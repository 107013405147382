

<template>
  <div class="login">
    <div class="login-con">
      <Card icon="log-in" title="欢迎登录" :bordered="false">
        <div class="form-con">
          <login-form @on-success-valid="handleSubmit"></login-form>
          <!-- <p class="login-tip">输入任意用户名和密码即可</p> -->
        </div>
      </Card>
    </div>

    <!-- <Modal
      v-model="showModal"
      title="选择门店"
    >
      <StoreList ref="storeList"></StoreList>
    </Modal> -->
  </div>
</template>

<script>
import LoginForm from '_c/loginForm'
import { mapMutations } from 'vuex'
import userList from '@/utils/userList'
// import StoreList from './storeManage/storeList.vue'
export default {
  data(){
    return {
      showModal:false
    }
  },
  components: {
    LoginForm,
    // StoreList
  },
  methods: {
    ...mapMutations([
      'SET_USER_TOKEN',
      'SET_USER_INFO',
      'SET_ORG_ID'
    ]),
    getTextInsideParentheses(str) {
      // 正则表达式匹配圆括号内的内容
      const regex = /（(.*?)）/;
      // 执行匹配并返回第一个匹配组（圆括号内的文本）
      const match = regex.exec(str);
      return match ? match[1] : null;
    },
    handleSubmit ({ userName, password }) {
      this.$api.login.getToken({ storeCode:userName, password }).then(async res=>{
        const { code, data, message } = res
        if(code==200){
          let matchUser = {}
          matchUser = userList.find(v=>v.role===data.roles[0])
          // TODO
          let name = data.name || userName
          matchUser.userName = this.getTextInsideParentheses(name) || name
          await this.SET_USER_TOKEN(data.token)
          await this.SET_USER_INFO(Object.assign({},data,matchUser))
          if(matchUser.isAdmin){
            // 选择门店
            // this.showModal = true
            // this.$nextTick(()=>{
            //   this.$refs.storeList.search({})
            // })
            this.$router.push({
              name: 'original-order-details'
            })
          }else{
            this.SET_ORG_ID(data.orgId)
            this.$router.push({
              name: 'order-details'
            })
          }
        }else{
          this.$Message.error( message || '登录失败！');
        }
      },()=>{
        this.$Message.error('账号或密码错误！');
      })
    }
  }
}
</script>

<style lang="less">
  .login{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-image: url('../assets/img/login-bg.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    &-con{
        position: absolute;
        right: 160px;
        top: 50%;
        transform: translateY(-60%);
        width: 300px;
        &-header{
            font-size: 16px;
            font-weight: 300;
            text-align: center;
            padding: 30px 0;
        }
        .form-con{
            padding: 10px 0 0;
        }
        .login-tip{
            font-size: 10px;
            text-align: center;
            color: #c3c3c3;
        }
    }
}
</style>
